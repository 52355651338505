import React, { useState, useRef } from "react"
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  Button,
  Textarea,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from "@chakra-ui/react"

import { useForm } from "react-hook-form"

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
  })

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const formRef = useRef(null);
  
  const sendFormData = async (event) => {
    //event.preventDefault()
    setProcessing(true);

    if (!formRef.current) {
      console.log("something went wrong with the ref");
    }

    const formData = new FormData(formRef.current);

    // convert signature to blob
    //const applicantSignatureBlob = await (await fetch(watchData.applicantSignature)).blob([applicantSignatureBlob], "applicantSignature.png");

    // append blob to formData
    //formData.append("applicantSignature", applicantSignatureBlob, "applicantSignature.png");


    // Use console.log() to see the data that is being sent to the server
    //console.log(Array.from(formData.entries()));

      fetch('/.netlify/functions/send-mayors-comment-box', {
           method: 'POST',
           body: formData,
       })
       // Convetring the response to JSON breaks status code
       .then(res => {
        console.log(res)
           if (res.status === 200) {
                 setSucceeded(true)
                 setProcessing(false)
                 reset()
                 //scrollTop()
             } else {
                 setSucceeded(false)
                 console.log("network error")
                 alert("Network error. Please try again later.")
             }
    }
 )
  };

  const SuccessMessage = () => (
    <Box my="20">
    <Alert status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
      >
    <AlertIcon boxSize='40px' mr={0}/>
      <AlertTitle mb="4">
        Message submitted successfully!
        </AlertTitle>
        <AlertDescription maxWidth='md'>
    Thanks for your submission. If you requested a response, we'll be in touch soon.
  </AlertDescription>
    </Alert>
  </Box>
  )


  return (
    <>
      <Box w="100%" mb="24">
      { succeeded ? <SuccessMessage /> : (
        <>
        <form onSubmit={handleSubmit(sendFormData)} ref={formRef}>
            <Box w="100%" mb={4}>
              <FormControl id="name">
                <FormLabel>Your Name</FormLabel>
                <Input type="text"
                    {...register("name")}
                />
              </FormControl>
            </Box>
            <Box w="100%" mb={4}>
              <FormControl id="phone">
                <FormLabel>Phone Number</FormLabel>
                <Input type="tel"
                    {...register("phone")}
                />
              </FormControl>
            </Box>
            <Box w="100%" mb={4}>
              <FormControl id="email">
                <FormLabel>Email address</FormLabel>
                <Input type="email" 
                   {...register("email")}
                   />
              </FormControl>
            </Box>
          
          <Box>
        <FormControl id="message" mb={4}>
          <FormLabel>Tell the Mayor:<Text color="#bf1650" as="span">*</Text></FormLabel>
            <Textarea placeholder=""
                {...register("message", {required: true} )}
                />
                {errors.message && <Text color="#bf1650">Please enter a message.</Text>}
            </FormControl>
            </Box>
            <Box>
                      <input
                        type="hidden"
                        value={new Date()}
                        name="timestamp"
                        {...register("timestamp", { required: true })}
                      />
                    </Box>
          <Box mt="4">
          <Button 
            disabled={!isValid || processing}
            type="submit"
            mt={4}
            isLoading={processing}
            loadingText='Submitting'
            >
              Submit
          </Button>
          </Box>
        </form>
        </>
      )
}
      </Box>
    </>
  )
}

export default ContactForm
